import { supabase } from '../supabaseClient';
import { useParams } from "react-router-dom";
import { useState, useEffect } from 'react'
import { RadioGroup } from '@headlessui/react'
import { EnvelopeIcon, PhoneIcon, CheckCircleIcon, XCircleIcon, PlusIcon, MinusIcon, BellIcon } from '@heroicons/react/20/solid'
import Eucalyptus from '../assets/Eucalyptus.jpg'
import emailjs from '@emailjs/browser';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ConfirmationList = [
  { id: 1, title: 'Zusage', description: 'Das kann man sich nicht entgehen lassen!', icon: CheckCircleIcon },
  { id: 2, title: 'Absage', description: 'Ich wäre wirklich gerne gekommen...', icon: XCircleIcon },
]

const Response = () => {
  const { id } = useParams();
  const [confirmation, setConfirmation] = useState(ConfirmationList[0].title);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [hotel, setHotel] = useState('');
  const [nmbrGuests, setNmbrGuests] = useState('');
  const [addGuests, setAddGuests] = useState([]);
  const updated_at = new Date();

  const addFormFields = () => {
    setAddGuests([...addGuests, { name: "", age: "Erwachsener" }])
  }

  const removeFormFields = (i) => {
    const newGuestValues = [...addGuests];
    newGuestValues.splice(i, 1);
    setAddGuests(newGuestValues)
  }

  const handleChange = (i, e) => {
    const newGuestValues = [...addGuests];
    newGuestValues[i][e.target.name] = e.target.value;
    setAddGuests(newGuestValues);
  }

  const handleClick = (e) => {
      if(confirmation === "Absage") {
          setNmbrGuests(0);
          setAddGuests([]);
          setHotel('');
      } else {
          setNmbrGuests(1 + addGuests.length)
      }
  }

  useEffect(() => {
    getGuestData();
  }, []);

  async function getGuestData() {

    const { data } = await supabase.from('registrations').select().eq('id', id).single();

    setFirstName(data.firstName)
    setLastName(data.lastName)
    setEmail(data.email)
    setPhone(data.phone)
    setConfirmation(data.confirmation)
    setNmbrGuests(data.nbmrGuests)
    setAddGuests(data.addGuests)
    setHotel(data.hotel)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
  
    const { data, error } = await supabase.from('registrations').update({firstName, lastName, email, phone, confirmation, hotel, nmbrGuests, addGuests, updated_at}).eq('id', id).select()

    if(data) {
      // EmailJS Config
      const serviceId = "service_ff6ulyf";
      const templateId = "template_xhjwn1o";
      const publicKey = "S-ewa3YC4O3pTPW_W";
      
      // Create TemplateParams
      const templateParams = {
          first_name: firstName,
          last_name: lastName,
          e_mail: email,
          cell: phone,
          Zusage: confirmation,
          AnzGuest: nmbrGuests,
          ZusGuests: addGuests,
          uid: id,
          Accommodation: hotel,
      };

      // Send email using EmailJS
      emailjs.send(serviceId, templateId, templateParams, publicKey)

      alert("Vielen Dank für Deine Rückmeldung!")
      window.location.href = "/"
}


if(error != null) {
  alert("Es ist ein Fehler aufgetreten.")
}

  }

  return (
    <div>
      <header>
        <div aria-hidden="true" className="relative">
            <img
            src={Eucalyptus}
            alt="Eucalyptus"
            className="h-96 w-full object-cover object-center"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-white" />
        </div>

        <div className="relative mx-auto -mt-12 max-w-7xl px-4 pb-16 sm:px-6 sm:pb-24 lg:px-8">
            <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
            <h1 className="text-7xl font-bold leading-tight tracking-tight text-yellow-600 font-classy">Aktualisiere Deine Rückmeldung</h1>
            </div>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <form onSubmit={handleSubmit}>
            <div className="space-y-12 pt-5">
              <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Persönliche Informationen</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">Deine Informationen und Rückmeldung zur Hochzeit kannst Du hier aktualisieren.</p>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                      Vorname
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        placeholder="Max"
                        value={firstName}
                        required={true}
                        pattern="^[A-Za-z-]{3,30}"
                        className="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-600 focus:outline-none sm:text-sm sm:leading-6"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                      Nachname
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        autoComplete="family-name"
                        placeholder="Mustermann"
                        value={lastName}
                        required={true}
                        pattern="^[A-Za-z-]{3,30}"
                        className="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-600 focus:outline-none sm:text-sm sm:leading-6"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                      E-Mail
                    </label>
                    <div className="relative mt-2">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="email"
                        placeholder="deine@email.de"
                        value={email}
                        required={true}
                        className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-600 focus:outline-none sm:text-sm sm:leading-6"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="cell" className="block text-sm font-medium leading-6 text-gray-900">
                      Mobil
                    </label>
                    <div className="relative mt-2">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <PhoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                      <input
                        type="text"
                        name="cell"
                        id="cell"
                        autoComplete="cell"
                        placeholder="+49 123 456789"
                        value={phone}
                        required={true}
                        className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-600 focus:outline-none sm:text-sm sm:leading-6"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-b border-gray-900/10 pb-12">
              <RadioGroup value={confirmation} onChange={setConfirmation}>
                <RadioGroup.Label className="text-base font-semibold leading-7 text-gray-900">
                  Rückmeldung
                </RadioGroup.Label>

                <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                  {ConfirmationList.map((Confirmation) => (
                    <RadioGroup.Option
                      key={Confirmation.id}
                      value={Confirmation.title}
                      id={Confirmation.title}
                      className={({ active }) =>
                        classNames(
                          active ? 'border-yellow-600 ring-2 ring-yellow-600' : 'border-gray-300',
                          'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                        )
                      }
                    >
                      {({ checked, active }) => (
                        <>
                          <span className="flex flex-1">
                            <span className="flex flex-col">
                              <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                {Confirmation.title}
                              </RadioGroup.Label>
                              <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                                {Confirmation.description}
                              </RadioGroup.Description>
                            </span>
                          </span>
                          <Confirmation.icon
                            className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-yellow-600')}
                            aria-hidden="true"
                          />
                          <span
                            className={classNames(
                              active ? 'border' : 'border-2',
                              checked ? 'border-yellow-600' : 'border-transparent',
                              'pointer-events-none absolute -inset-px rounded-lg'
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
              </div>

              <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Begleitungen</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">Ich komme nicht alleine...</p>

                {addGuests.map((element, index) => (
                  <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6" key={index} id={index}>
                    <div className="sm:col-span-3">
                      <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                        Name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          value={element.name || ""}
                          autoComplete="name"
                          placeholder="Max Mustermann"
                          required={true}
                          disabled={confirmation === "Absage"}
                          className="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-600 disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                          onChange={e => handleChange(index, e)}
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-2">
                      <label htmlFor="ageGuest" className="block text-sm font-medium leading-6 text-gray-900">
                        Altersgruppe
                      </label>
                      <div className="mt-2">
                        <select
                          type="text"
                          name="age"
                          id="age"
                          value={element.age}
                          disabled={confirmation === "Absage"}
                          autoComplete="ageGroup"
                          className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-yellow-600 disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                          onChange={e => handleChange(index, e)}
                        >
                              <option value="Erwachsener">Erwachsener</option>
                              <option value="Kind (0-5 Jahre)">Kind (0-5 Jahre)</option>
                              <option value="Kind (6-11 Jahre)">Kind (6-11 Jahre)</option>
                        </select>
                      </div>
                    </div>
                    <div className="sm:col-span-1">
                      <div className="mt-2">
                      <label htmlFor="AddButton" className="hidden md:block text-sm font-medium leading-6 text-gray-900 invisible">
                        Button
                      </label>
                        <button
                          type="button"
                          disabled={confirmation === "Absage"}
                          className="rounded-full bg-red-500 p-1.5 text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-700 disabled:bg-gray-400 disabled:cursor-not-allowed"
                          onClick={() => {removeFormFields(index)}}
                        >
                          <MinusIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="mt-5 flex justify-start">
                  <button
                    type="button"
                    className="inline-flex items-center gap-x-1.5 rounded-md bg-yellow-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600 disabled:bg-gray-400 disabled:cursor-not-allowed"
                    onClick={(e) => {addFormFields()}}
                    disabled={confirmation === "Absage"}
                  >
                    <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    Begleitung
                  </button>
                </div>
              </div> 

              <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Hotel</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">Du kommst in einem Hotel unter? Lass es uns gerne für ein Shuttleservice wissen.</p>

                <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                        <div className="mt-2">
                        <input
                            type="text"
                            name="hotel"
                            id="hotel"
                            placeholder="Hotel"
                            value={hotel}
                            disabled={confirmation === "Absage"}
                            className="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-600 focus:outline-none disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                            onChange={(e) => setHotel(e.target.value)}
                        />
                        </div>
                    </div>
                </div>
              </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                  <button
                    type="submit"
                    className="rounded-md bg-yellow-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
                    onClick={(e) => {handleClick()}}
                  >
                    Aktualisieren
                  </button>
                </div>
              </div>
            </form>
          </div>
        </main>
      </div>
)
};

export default Response;