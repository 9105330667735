import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import Header from './components/header';
import FooterMenu from './components/footer';

import Home from "./pages/Home";
import Hotels from "./pages/Hotels";
import Details from "./pages/Details";
import Timeline from "./pages/Timeline";
import RSVP from "./pages/RSVP";
import Response from './pages/Response';
import Error404 from "./pages/Error404";

const root = ReactDOM.createRoot(document.getElementById('wedding-app'));
root.render(
  <div className="min-h-full">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Header />}>
          <Route index element={<Home />} />
          <Route path="hotels" element={<Hotels />} />
          <Route path="details" element={<Details />} />
          <Route path="timeline" element={<Timeline />} />
          <Route path="rsvp" element={<RSVP />} />
          <Route path="response/:id" element={<Response />} />
          <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
    <FooterMenu />
  </div>
)