
  export default function FooterMenu() {
    return (
    <footer className="bg-white">
      <div className="mx-auto border-t border-gray-200 max-w-7xl px-6 py-8 mt-10 md:flex md:items-center md:justify-between lg:px-8">
        <div className="md:order-1 md:mt-0">
          <p className="text-center text-xs leading-5 text-gray-500">
            &copy; {new Date().getFullYear()} Anne & Marcel. Kontakt: <a href="mailto:brautpaar@anne-marcel-2024.wedding?subject=Hochzeit" className="text-yellow-600 hover:text-yellow-700">brautpaar@anne-marcel-2024.wedding</a>.
          </p>
        </div>
      </div>
    </footer>
    )
  }