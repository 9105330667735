const Error404 = () => {
    return (
      <>
      <main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div class="text-center">
          <p class="text-base font-semibold text-yellow-600">Error 404</p>
          <h1 class="mt-8 text-8xl font-bold tracking-tight text-gray-900 font-classy sm:text-5xl">Seite nicht gefunden</h1>
          <p class="mt-6 text-base leading-7 text-gray-600">Entschuldige, die gewünschte Seite konnte nicht gefunden werden.</p>
          <div class="mt-10 flex items-center justify-center gap-x-6">
            <a href="/" class="rounded-md bg-yellow-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600">Zur Startseite</a>
          </div>
        </div>
</main>
    </>
    )
  };
  
  export default Error404;