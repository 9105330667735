"use client";

import { APIProvider, Map, AdvancedMarker } from "@vis.gl/react-google-maps";
import { BellAlertIcon, LinkIcon, MapIcon, MapPinIcon } from '@heroicons/react/24/outline'
import HotelArkadia from '../assets/wedding_anne_marcel_hotels_1.png'
import Hotel3Hoefe from '../assets/wedding_anne_marcel_hotels_2.png'
import HotelTaunusTagung from '../assets/wedding_anne_marcel_hotels_3.png'
import HotelBB from '../assets/wedding_anne_marcel_hotels_4.png'
import HotelMaritim from '../assets/wedding_anne_marcel_hotels_5.png'
import HotelSteigenberger from '../assets/wedding_anne_marcel_hotels_6.png'
import WeddingLocation from '../assets/wedding-location-anne-marcel.png'

const locations = [
  { id: 1,  initial: <BellAlertIcon className='-ml-0.4 h-5 w-5' aria-hidden='true' />,  style: "absolute left-0 top-0 flex h-8 w-8 items-center justify-center rounded-lg bg-yellow-600 text-white font-semibold" , title: "Hochzeitslocation: Taktgefühl", position: {lat: 50.25695553084061, lng: 8.655907841217529}, image: WeddingLocation, address: "Edouard-Desor-Straße 2, 61381 Friedrichsdorf" },
  { id: 2,  initial: "A",  style: "absolute left-0 top-0 flex h-6 w-6 items-center justify-center rounded-lg bg-pink-800 text-white font-semibold" , title: "Arkadia Apartments Rhein-Main", position: {lat: 50.254113550973024, lng: 8.645672500859977}, distance: "1,3km", verkehrsmittel: "Auto: 3min, Fußweg: 15min", image: HotelArkadia, address: "Am Houiller Platz 2, 61381 Friedrichsdorf", href: "http://www.arkadia-hotel.de/", routenplanung: "https://www.google.com/maps/dir/Arkadia+Apartments+Rhein-Main,+Am+Houiller+Platz,+Friedrichsdorf/Taktgef%C3%BChl+Eventhaus+%E2%80%93+Eventlocation,+Edouard-Desor-Stra%C3%9Fe,+Friedrichsdorf/@50.2552228,8.6460031,16z" },
  { id: 3,  initial: "B",  style: "absolute left-0 top-0 flex h-6 w-6 items-center justify-center rounded-lg bg-pink-800 text-white font-semibold" , title: "Hotel drei Höfe", position: {lat: 50.25552936485704, lng: 8.639449810240494}, distance: "2,4km", verkehrsmittel: "Auto: 6min, Fußweg: 21min", image: Hotel3Hoefe, address: "Hugenottenstraße 68, 61381 Friedrichsdorf", href: "https://www.hotel-drei-hoefe.de/", routenplanung: "https://www.google.com/maps/dir/Hotel+Drei+H%C3%B6fe+Gmbh,+Hugenottenstra%C3%9Fe,+Friedrichsdorf/Taktgef%C3%BChl+Eventhaus+%E2%80%93+Eventlocation,+Edouard-Desor-Stra%C3%9Fe,+Friedrichsdorf/@50.2558935,8.6358406,13z/" },
  { id: 4,  initial: "C",  style: "absolute left-0 top-0 flex h-6 w-6 items-center justify-center rounded-lg bg-pink-800 text-white font-semibold" , title: "TaunusTagungshotel", position: {lat: 50.26696387143899, lng: 8.629971414351099}, distance: "2,9km", verkehrsmittel: "Auto: 6min, Fußweg: 37min", image: HotelTaunusTagung, address: "Lochmühlenweg 3, 61381 Friedrichsdorf", href: "http://www.taunustagungshotel.de/", routenplanung: "https://www.google.com/maps/dir/TaunusTagungsHotel,+Lochm%C3%BChlenweg,+Friedrichsdorf/Taktgef%C3%BChl+Eventhaus+%E2%80%93+Eventlocation,+Edouard-Desor-Stra%C3%9Fe,+Friedrichsdorf/@50.2604951,8.6220507,13z/" },
  { id: 5,  initial: "D",  style: "absolute left-0 top-0 flex h-6 w-6 items-center justify-center rounded-lg bg-pink-800 text-white font-semibold" , title: "B&B HOTEL Bad Homburg", position: {lat: 50.219590910041845, lng: 8.620754904526517}, distance: "8,2km", verkehrsmittel: "Auto: 11min, Bahn: 23min (davon ca. 15min Fußweg)", image: HotelBB, address: "Ludwigstraße 3, 61348 Bad Homburg", href: "https://www.hotel-bb.com/de/hotel/bad-homburg", routenplanung: "https://www.google.com/maps/dir/B%26B+HOTEL+Bad+Homburg,+Horexstra%C3%9Fe,+Bad+Homburg+vor+der+H%C3%B6he/Taktgef%C3%BChl+Eventhaus+%E2%80%93+Eventlocation,+Edouard-Desor-Stra%C3%9Fe,+Friedrichsdorf/@50.2354085,8.59424,13z/" },
  { id: 6,  initial: "E",  style: "absolute left-0 top-0 flex h-6 w-6 items-center justify-center rounded-lg bg-pink-800 text-white font-semibold" , title: "Maritim Hotel Bad Homburg", position: {lat: 50.22706711531102, lng: 8.617874346901171}, distance: "7,6km", verkehrsmittel: "Auto: 12min, Bahn: 42min (davon ca. 35min Fußweg)", image: HotelMaritim, address: "Ludwigstraße 3, 61348 Bad Homburg", href: "https://www.maritim.de/de/hotels/deutschland/hotel-bad-homburg", routenplanung: "https://www.google.com/maps/dir/Maritim+Hotel+Bad+Homburg,+Ludwigstra%C3%9Fe,+Bad+Homburg+vor+der+H%C3%B6he/Taktgef%C3%BChl+Eventhaus+%E2%80%93+Eventlocation,+Edouard-Desor-Stra%C3%9Fe,+Friedrichsdorf/@50.228376,8.5822752,13z/" },
  { id: 7,  initial: "F",  style: "absolute left-0 top-0 flex h-6 w-6 items-center justify-center rounded-lg bg-pink-800 text-white font-semibold" , title: "Steigenberger Hotel Bad Homburg", position: {lat: 50.22612830736103, lng: 8.6224941091305}, distance: "6,7km", verkehrsmittel: "Auto: 11min, Bahn: 41min (davon ca. 35min Fußweg)", image: HotelSteigenberger, address: "Kaiser-Friedrich-Promenade 69-75, 61348 Bad Homburg", href: "https://hrewards.com/de/steigenberger-hotel-bad-homburg", routenplanung: "https://www.google.com/maps/dir/Steigenberger+Hotel+Bad+Homburg,+Kaiser-Friedrich-Promenade+69-75,+61348+Bad+Homburg+vor+der+H%C3%B6he/Taktgef%C3%BChl+Eventhaus+%E2%80%93+Eventlocation,+Edouard-Desor-Stra%C3%9Fe,+Friedrichsdorf/@50.2381615,8.6196579,14z/" },
]

const Hotels = () => {

  const position = { lat: 50.242219, lng: 8.6400529 };

    return (
      <APIProvider apiKey="AIzaSyBVfA32DlLbzeaJXlbd9ELiStPzjczbGn4">
        <main>
          <div className="min-h-[650px] relative isolate overflow-hidden bg-gray-100">
            <div style={{height: "650px", width: "100%"}}>
              <Map zoom={13} center={position} mapId="5a08f9240be292fd">
                {locations.map((location) => (
                  <AdvancedMarker key={location.id} position={location.position}>
                    <div className={location.style}>
                      {location.initial}
                    </div>                    
                  </AdvancedMarker>
                ))}
              </Map>
              </div>
          </div>

          <div className="bg-white py-16 sm:py-24">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-7xl lg:mx-0 mb-8">
                <h2 className="text-4xl font-bold tracking-tight text-yellow-600 sm:text-4xl">
                  Die Veranstaltungs-Location
                </h2>
              </div>
                  <div className="pt-8 pb-8 sm:flex border-b-2 border-t-2">
                    <div className="mb-4 flex-shrink-0 sm:mb-0 sm:mr-4">
                  <img className="h-48 bg-white text-gray-300 sm:w-64" src={locations[0].image} alt={locations[0].title}/>
                </div>
                <div>
                  <div className="text-white flex items-center justify-center font-semibold bg-yellow-600 h-6 w-6 text-center rounded-lg ">{locations[0].initial}</div>
                  <h4 className="text-lg font-bold">{locations[0].title}</h4>
                    <div className="flex mt-1">
                      <MapPinIcon className='-ml-0.4 h-4 w-4 text-gray-600 mr-2 mt-1' aria-hidden='true' /><b className="pr-2">Adresse:</b>Edouard-Desor-Straße 2, 61381 Friedrichsdorf
                    </div>
                    <div className="flex mt-1">
                      <a href="https://www.google.com/maps/dir//Taktgef%C3%BChl+Eventhaus+%E2%80%93+Eventlocation,+Edouard-Desor-Stra%C3%9Fe+2,+61381+Friedrichsdorf/@50.2569611,8.653277,17z" className="flex text-yellow-600" target="_blank" rel="noreferrer"><MapIcon className='-ml-0.4 h-4 w-4 text-gray-600 mr-2 mt-1' aria-hidden='true' />Routenplaner zur Hochzeitslocation</a>
                    </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-7xl lg:mx-0">
                <h2 className="text-4xl font-bold tracking-tight text-yellow-600 sm:text-4xl">
                  Hotel-Vorschläge in der Nähe
                </h2>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Nachfolgend sind ein paar Hotelvorschläge in der näheren Umgebung zur Hochzeitslocation.
                </p>
              </div>
                {locations.slice(1).map((location) => (
                  <div className="pt-12 pb-8 sm:flex border-b-2 last:border-b-0" key={location.id}>
                    <div className="mb-4 flex-shrink-0 sm:mb-0 sm:mr-4">
                  <img className="h-48 bg-white text-gray-300" src={location.image} alt={location.title} />
                </div>
                <div>
                  <div className="text-white font-semibold bg-pink-800 h-6 w-6 text-center rounded-lg ">{location.initial}</div>
                  <h4 className="text-lg font-bold mt-2">{location.title}</h4>
                    <div className="flex mt-1">
                      <a href={location.href} className="flex text-yellow-600" target="_blank" rel="noreferrer"><LinkIcon className='-ml-0.4 h-4 w-4 text-gray-600 mr-2 mt-1' aria-hidden='true' />Link zum Hotel</a>
                    </div>
                    <div className="flex mt-1">
                      <MapPinIcon className='-ml-0.4 h-4 w-4 text-gray-600 mr-2 mt-1' aria-hidden='true' /><b className="pr-2">Adresse:</b>{location.address}
                    </div>
                    <div className="flex mt-1">
                      <BellAlertIcon className='-ml-0.4 h-4 w-4 text-gray-600 mr-2 mt-1' aria-hidden='true' /> Distanz zur Location: {location.distance} ({location.verkehrsmittel})
                    </div>
                    <div className="flex mt-1">
                    <a href={location.routenplanung} className="flex text-yellow-600" target="_blank" rel="noreferrer"><MapIcon className='-ml-0.4 h-4 w-4 text-gray-600 mr-2 mt-1' aria-hidden='true' />Routenplaner zur Hochzeitslocation</a>
                    </div>
                </div>
                  </div>
                ))}
      
            </div>
          </div>
        </main>
      </APIProvider>
    );
  };
  
  export default Hotels;