import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import { HomeModernIcon, MapIcon } from '@heroicons/react/20/solid'
import WeddingLocation from '../assets/Location_Wedding_Anne-Marcel-2024.png'
import WeddingDresscode from '../assets/Dresscode_Wedding_Anne_Marcel_2024.png'
import WeddingGifts from '../assets/Gifts_Wedding_Anne-Marcel-2024.png'
import Eucalyptus from '../assets/Eucalyptus.jpg'

const faqs = [
  {question: "Darf ich meinen Partner mitbringen?", answer: "Selbstverständlich! Eure Partner sind natürlich ebenfalls herzlich eingeladen. Gib diese bitte bei der Rückmeldung als Begleitung an."},
  {question: "Dürfen wir unsere Kinder mitbringen?", answer: "Ihr dürft natürlich gerne Eure Kinder mitbringen. Eine Kinderbetreuung ist nicht organisiert. Für die kleineren Kinder steht jedoch ein Kinderstuhl am Tisch bereit. Gib diese bitte bei der Rückmeldung als Begleitung an."},
  {question: "Dürfen wir während der Trauung Fotos machen?", answer: "Wir werden den ganzen Tag von einem Fotografen begleitet. Wenn Ihr wünscht, könnte Ihr aber auch gerne während der Trauung selbst Fotos machen."},
  {question: "Wird es auch ein vegetarisches Gericht geben?", answer: "Wir haben ein umfang- und abwechslungsreiches Buffet zusammengestellt, bei dem für alle etwas dabei sein sollte."},
  {question: "Gibt es Hotels in der Nähe?", answer: "Eine Hotelauswahl haben wir Euch auf der Seite Hotels gesondert bereitgestellt. Sollten die Auswahl bereits ausgebucht sein, kommt gerne auf uns zu."},
  {question: "Wir planen eine Überraschung oder eine Rede?", answer: "Solltet Ihr für unsere Feier eine Überraschung oder Rede planen, stimmt Euch hierzu bitte kurz mit unserem Trauzeugen (Niclas Bechtel: +49 151 41451452) im Vorfeld ab."},
  {question: "Bis wann müssen wir Zu- oder Absagen?", answer: "Für die weitere Planung benötigen wir Eure Rückmeldung bis spätestens 18. Februar 2024."},
]

const Details = () => {
    return (
        <>
           <header>
           <div aria-hidden="true" className="relative">
                <img
                src={Eucalyptus}
                alt="Eucalyptus"
                className="h-96 w-full object-cover object-center"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-white" />
            </div>

            <div className="relative mx-auto -mt-12 max-w-7xl px-4 pb-16 sm:px-6 sm:pb-24 lg:px-8">
                <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
                <h1 className="text-7xl font-bold leading-tight tracking-tight text-yellow-600 font-classy">Details zur Hochzeit</h1>
                </div>
                </div>
          </header>
          <main>

          <div className="overflow-hidden bg-white py-16 sm:py-24">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                <div className="lg:pr-8 lg:pt-4">
                    <div className="lg:max-w-lg">
                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900">Die Location</p>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        Die freie Trauung sowie das gemeinsame Dinner mit anschließender Party finden im Taktgefühl Eventhaus Friedrichsdorf statt.
                    </p>
                    <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                        <div className="relative pl-9">
                            <dt className="inline font-semibold text-gray-900">
                            <HomeModernIcon className="absolute left-1 top-1 h-5 w-5 text-yellow-600" aria-hidden="true" />
                            Adresse:
                            </dt>{' '}
                            <dd className="inline">Taktgefühl Eventhaus, Edouard-Desor-Straße 2, 61381 Friedrichsdorf</dd>
                        </div>
                        <div className="relative pl-9">
                            <dt className="inline font-semibold text-gray-900">
                            <MapIcon className="absolute left-1 top-1 h-5 w-5 text-yellow-600" aria-hidden="true" />
                            Parkmöglichkeiten:
                            </dt>{' '}
                            <dd className="inline">Ausreichende und kostenlose Parkmöglichkeiten stehen Euch direkt vor dem Haus sowie dem angrenzenden Sportpark zur Verfügung.</dd>
                        </div>
                    </dl>
                    </div>
                </div>
                <img
                    src={WeddingLocation}
                    alt="Hochzeitslocation"
                    className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                    width={2432}
                    height={1442}
                />
                </div>
                </div>
            </div>

            <div className="overflow-hidden bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:ml-auto lg:pl-4 lg:pt-4">
                        <div className="lg:max-w-lg">
                        
                        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Dresscode</p>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            Wir haben was zu feiern, also lasst uns in Schale werfen! Aber grundsätzlich gilt: Wohlfühlen.
                        </p>
                        </div>
                    </div>
                    <div className="flex items-start justify-end lg:order-first">
                        <img
                        src={WeddingDresscode}
                        alt="Wedding Dresscode"
                        className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                        width={2432}
                        height={1442}
                        />
                    </div>
                    </div>
                </div>
            </div>

            <div className="overflow-hidden bg-white py-16 sm:py-24">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:pr-8 lg:pt-4">
                        <div className="lg:max-w-lg">
                        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Geschenke</p>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                        Das größte Geschenk ist eure Anwesenheit! Falls Ihr uns darüberhinaus eine Freude bereiten möchtet, würden wir uns über einen Beitrag zu unseren Flitterwochen freuen.
                        </p>
                        </div>
                    </div>
                    <img
                        src={WeddingGifts}
                        alt="WeddingGifts"
                        className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                        width={2432}
                        height={1442}
                    />
                    </div>
                </div>
            </div>

            <div className="mx-auto max-w-7xl pt-16 px-4 sm:px-6 lg:px-8">
              <h1 className="text-7xl font-bold leading-tight tracking-tight text-yellow-600 font-classy">Weitere Fragen?</h1>
            </div>

            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                {/* FAQ section */}
                <div className="mx-auto max-w-7xl divide-y divide-gray-900/10">
                    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                    {faqs.map((faq) => (
                        <Disclosure as="div" key={faq.question} className="pt-6">
                        {({ open }) => (
                            <>
                            <dt>
                                <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                <span className="text-base font-semibold leading-7">{faq.question}</span>
                                <span className="ml-6 flex h-7 items-center">
                                    {open ? (
                                    <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                    ) : (
                                    <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                    )}
                                </span>
                                </Disclosure.Button>
                            </dt>
                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                            </Disclosure.Panel>
                            </>
                        )}
                        </Disclosure>
                    ))}
                    </dl>
                </div>
            </div>
          </main>
        </>
    )
  };
  
  export default Details;